import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CertificateService } from '../../services/certificate.service';
import { SpinnerService } from '../../services/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss'
})
export class HomePageComponent {

  public formSearch: FormGroup;
  public submitted: boolean = false;

  constructor(
    private certificateService: CertificateService,
    private formBuilder: FormBuilder,
    private router: Router,
    public spinnerService: SpinnerService,
  ) {

    this.formSearch = this.formBuilder.group({
      folio: ['', Validators.required],
    });
    
  }

  get formControls() {
    return this.formSearch.controls;
  }

  async onSubmit() {

    console.log('onSubmit');
    

    this.submitted = true;
    if (this.formSearch.invalid) {
      return;
    }

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Buscando certificado...';

    const certificate = await this.certificateService.get(
      this.formSearch.value.folio
    );

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    if(certificate) {
      this.router.navigate(['/certificates', certificate.idCertificate]);
    }
  }

}
