<div 
  class="container-fluid bg-center bg-machine"
  style="height: 100vh;">
  <div class="container h-100">
    <div class="row h-100 py-4">
      <div class="col-12">
        <div class="row">

          <div class="col-9 col-md-4 col-lg-3 mx-auto mx-md-0">
            <img src="./assets/logo.webp" width="100%">
          </div>

        </div>
      </div>
      <div class="col-12 pt-5">
        <div class="row">

          <div class="col-12 col-md-8 col-lg-6 col-xl-5 mx-auto pt-5">
            <form 
              class="row pt-5"
              [formGroup]="formSearch"
              (ngSubmit)="onSubmit()"
              id="formSearch">

              <div class="col-12 form-group mb-3 p-0">
                <input
                  class="form-control rounded-4"
                  formControlName="folio"
                  placeholder="Introduce tu número de inspección (XXX-XXXXXX-XX)">
                  @if (submitted && formControls['folio'].errors) {
                    <div class="invalid-feedback">
                      @if (formControls['folio'].errors['required']) {
                        <div>Por favor, ingrese un número de folio</div>
                      }
                    </div>
                  }
              </div>

              <button 
                class="btn btn-primary rounded-4"
                type="submit"
                form="formSearch">
                Buscar
              </button>

            </form>
          </div>

        </div>
      </div>
      <div class="col-12">
        <div class="row text-white h-100">
  
          <div class="col-12 d-flex align-items-end">
            <div class="row w-100">
              <div class="col-12 col-md-6 mt-auto d-flex align-items-center justify-content-center justify-content-md-start text-white text-decoration-none">
                <i class="material-icons-outlined me-2">phone</i>+52 59 9998 2040
              </div>
      
              <a 
                class="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end text-white text-decoration-none"
                href="mailto:contacto@varever.com.mx">
                <i class="material-icons-outlined me-2">email</i>{{ 'contacto@varever.com.mx' }}
              </a>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </div>
</div>