// Certificate.model.ts

export interface ICertificate {
  idCertificate: string;
  idStandard: string;
  idCompany: string;
  idMachine: string;
  workingHours: number;
  review: string;
  purchaseOrder: string;
  folio: string;
  auxCarriedOutBy: string;
  handledBy: string;
  idSignHandledBy?: string;
  idSignCarriedOnBy?: string;
  approved: boolean;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: string;
  updatedAt: string;
}

export class Certificate {
  idCertificate: string;
  idStandard: string;
  idCompany: string;
  idMachine: string;
  workingHours: number;
  review: string;
  purchaseOrder: string;
  folio: string;
  auxCarriedOutBy: string;
  handledBy: string;
  idSignHandledBy?: string;
  idSignCarriedOnBy?: string;
  approved: boolean;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: Date;
  updatedAt: Date;

  constructor(data: ICertificate) {
    this.idCertificate = data.idCertificate;
    this.idStandard = data.idStandard;
    this.idCompany = data.idCompany;
    this.idMachine = data.idMachine;
    this.workingHours = data.workingHours;
    this.review = data.review;
    this.purchaseOrder = data.purchaseOrder;
    this.folio = data.folio;
    this.auxCarriedOutBy = data.auxCarriedOutBy;
    this.handledBy = data.handledBy;
    this.idSignHandledBy = data.idSignHandledBy;
    this.idSignCarriedOnBy = data.idSignCarriedOnBy;
    this.approved = data.approved;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }
}
