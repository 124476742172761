import { Component } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';
import { ActivatedRoute } from '@angular/router';
import { CertificateService } from '../../services/certificate.service';

@Component({
  selector: 'app-certificate-page',
  templateUrl: './certificate-page.component.html',
  styleUrl: './certificate-page.component.scss'
})
export class CertificatePageComponent {
  private idCertificate: string = '';

  public userAgent: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private certificateService: CertificateService,
    public spinnerService: SpinnerService,
  ) {

    // this.spinnerService.loading = true;
    // this.spinnerService.message = 'Validando certificado...';

    this.userAgent = navigator.userAgent;

    console.log(this.userAgent);
    

    this.activatedRoute.params.subscribe(params => {

      this.idCertificate = params['idCertificate'];

      // this.showCertificate();
      // this.downloadCertificate();
      this.getCertificate();
      
    });

  }

  async getCertificate() {

    this.spinnerService.loading = true;
    this.spinnerService.message = `Validando certificado...`;

    const certificate = await this.certificateService.get(
      this.idCertificate
    );

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    if(certificate) {

      if(this.userAgent.includes('Mobile')) {
        this.downloadCertificate();
      } else {
        this.showCertificate();
      }
      
    }

  }

  async downloadCertificate() {
    this.spinnerService.loading = true;
    this.spinnerService.message = `Descargando certificado...`;
  
    const base64data = await this.certificateService.getCertificate(this.idCertificate);
  
    // Asumiendo que base64data es un string base64 sin el prefijo de Data URL
    const contentType = 'application/pdf'; // Cambia esto según el tipo de archivo que esperas
    const b64Data = base64data.split(',')[1]; // Quita el prefijo si ya está presente en la cadena base64
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
  
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: contentType });
    const blobUrl = URL.createObjectURL(blob);
  
    this.spinnerService.loading = false;
    this.spinnerService.message = '';
  
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = 'certificate.pdf'; // Nombre del archivo a descargar
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  async showCertificate() {

    this.spinnerService.loading = true;
    this.spinnerService.message = `Descargando certificado...`;

    const base64data = await this.certificateService.getCertificate(
      this.idCertificate
    );

    // Asumiendo que base64data es un string base64 sin el prefijo de Data URL
    const contentType = 'application/pdf'; // Cambia esto según el tipo de archivo que esperas
    const b64Data = base64data.split(',')[1]; // Quita el prefijo si ya está presente en la cadena base64
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    const blobUrl = URL.createObjectURL(blob);

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    window.open(blobUrl);
  }

}
