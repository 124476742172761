import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Certificate, ICertificate } from '../models/certificate.model';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  constructor(
    private http: HttpClient
  ) { }

  /*
   ██████╗ ███████╗████████╗
  ██╔════╝ ██╔════╝╚══██╔══╝
  ██║  ███╗█████╗     ██║
  ██║   ██║██╔══╝     ██║
  ╚██████╔╝███████╗   ██║
   ╚═════╝ ╚══════╝   ╚═╝

  */
 /**
  * FUNCTION get
  * 
  * @param folio 
  * @returns 
  */
  async get(
    folio: string
  ): Promise<Certificate | undefined> {

    let certificate: Certificate | undefined = undefined;

    const urlApi = `${environment.urlApi}/certificates/${folio}`;

    try {

      const response = await new Promise<ServerResponse<{ certificate: ICertificate }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ certificate: ICertificate }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{ certificate: ICertificate }>
              ) => resolve(
                new ServerResponse<{ certificate: ICertificate }>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        certificate = new Certificate(response.data!.certificate);
      }
      
    } catch(error) {
      console.log(error);

    }

    return certificate;

  }

  /*
   ██████╗ ███████╗████████╗ ██████╗███████╗██████╗ ████████╗██╗███████╗██╗ ██████╗ █████╗ ████████╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔════╝██╔════╝██╔══██╗╚══██╔══╝██║██╔════╝██║██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║  ███╗█████╗     ██║   ██║     █████╗  ██████╔╝   ██║   ██║█████╗  ██║██║     ███████║   ██║   █████╗
  ██║   ██║██╔══╝     ██║   ██║     ██╔══╝  ██╔══██╗   ██║   ██║██╔══╝  ██║██║     ██╔══██║   ██║   ██╔══╝
  ╚██████╔╝███████╗   ██║   ╚██████╗███████╗██║  ██║   ██║   ██║██║     ██║╚██████╗██║  ██║   ██║   ███████╗
   ╚═════╝ ╚══════╝   ╚═╝    ╚═════╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝╚═╝     ╚═╝ ╚═════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  /**
   * FUNCTION getCertificate
   * 
   * @param idCertificate 
   * @returns 
   */
  async getCertificate(
    idCertificate: string
  ): Promise<String> {

    let fileBase64 = '';

    const urlApi = `${environment.urlApi}/certificates/${idCertificate}/pdf`;

    try {

      const response = await new Promise<ServerResponse<{ file: string }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ file: string }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{ file: string }>
              ) => resolve(
                new ServerResponse<{ file: string }>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        fileBase64 = response.data!.file;
      }
      
    } catch(error) {
      console.log(error);

    }

    return fileBase64;

  }
}
